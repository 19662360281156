import { useEffect, useState } from 'react';
import useUserSession from '@src/hooks/useUserSession';
import getConfig from '@src/util/getConfig';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';

const SentryProvider = () => {
    const { user: sessionUser } = useUserSession();
    const config = getConfig();
    const [isSentryReady, setIsSentryReady] = useState(false);

    useEffect(() => {
        if (isSentryReady) return;
        if (!config) return;
        if (!sessionUser) return;

        // Initialize Sentry
        Sentry.init({
            dsn: config?.sentry_dsn,
            environment: config?.env_name,
            integrations: [
                // TODO: add integrations see for Headers config: https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/
                // new Sentry.BrowserTracing({
                //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                //     tracePropagationTargets: ['localhost', /^https:\/\/dev01\.gate-serv\.net\/api/],

                // }),
                new Sentry.Replay({
                    maskAllText: false,
                    blockAllMedia: false,
                }),

            ],
            // Performance Monitoring
            //  tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        // Set user context
        Sentry.setUser({
            id: sessionUser.id,
            email: sessionUser.user_email,
        });

        setIsSentryReady(true);
    }, [isSentryReady, sessionUser, config]);

    return null;
};

export default SentryProvider;
